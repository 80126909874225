<div ngClass.gt-sm="app-container-desktop" ngClass.lt-md="app-container-mobile" fxLayout="column" fxFill>
    <zui-sidenav
        #sidenav
        [sidenavParams]="sidenavConfig"
        [header]="sidenavHeader"
        [menu]="sidenavMenu"
        (emitSelectedCompany)="selectCompany($event)"
    >
        <zui-progress-spinner
                *ngIf="isLoading$ | async"
                class="app-root-progress-spinner"
                color="accent"
                diameter=256
        ></zui-progress-spinner>
        <router-outlet></router-outlet>
    </zui-sidenav>
    <div class="content-container">
        <zui-footer-component></zui-footer-component>
    </div>
</div>