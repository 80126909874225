<!--If a menu isn't passed in, default based on environment is used-->
<div class="content-container" fxFlex>
    <zui-sidenav-header class="header-bar-sidenav" [title]="title" (menuToggled)="onSideNavMobileMenuButtonToggled($event)"></zui-sidenav-header>
    <zui-hos-navigation [activeAccount]="account"></zui-hos-navigation>
    <zui-hos-banner [showBanner]="dispatcherIsNull" [bannerText]="text" [icon]="'warning'"></zui-hos-banner>
    <ng-container *ngIf="(activeAccount | async); else accountSpinner">
        <router-outlet></router-outlet>
    </ng-container>
    <ng-template #accountSpinner>
        <div class="account-spinner">
            <!--
              Styles for progress spinner applied from styles/styles.scss.
              Progress spinner pattern library theme has compile issue with the latest zonar-ui material library.
            -->
            <zui-progress-spinner class="progress-spinner" color="accent" diameter=256></zui-progress-spinner>
        </div>
    </ng-template>
</div>


