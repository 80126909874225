import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { delay, distinctUntilChanged} from 'rxjs/operators';
import { AppService } from './app.service';
import {desktopMenu, mobileMenu, sidenavGlobalConfig, sidenavHeaderConfig} from '@app/shared/sidenav.config';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Store} from '@ngrx/store';
import {State as UserState} from '@app/modules/udl/state';
import {accountCodesSelector, accountSelector} from '@app/modules/udl/state/user.selectors';
import {DataDogService} from '@app/modules/services/datadog.service';
import {environment} from '@env/environment';

@Component({
    selector: 'app-root',
    templateUrl: 'app-root.html',
    styleUrls: ['app.component.scss']
})
export class AppRootComponent implements OnInit, OnDestroy {
    public isLoading$!: Observable<boolean>;
    public account = undefined;
    public activeAccount = new BehaviorSubject<boolean>(false);
    public hasDefaultCompany = false;

    sidenavConfig = sidenavGlobalConfig;
    sidenavHeader = sidenavHeaderConfig;
    sidenavMenu = undefined;

    constructor(
        private userStore: Store<UserState>,
        private appService: AppService,
        private datadogService: DataDogService,
        private deviceService: DeviceDetectorService
    ) {
        // Adding 100 ms delay to avoid flickering effect
        this.isLoading$ = this.appService.isAppDataLoading$.pipe(delay(100));
        const device = this.deviceService.getDeviceInfo();
        if (device.deviceType === 'mobile') {
            this.sidenavMenu = mobileMenu;
        } else {
            this.sidenavMenu = desktopMenu;
        }

        this.userStore.select(accountCodesSelector).subscribe((x) => {
           this.account = x;
        });
    }

    ngOnInit() {
        this.datadogService.startSessionReplayRecording();
        this.userStore.select(accountSelector).pipe(distinctUntilChanged()).subscribe((acct) => {
            this.account = acct;
            this.sidenavMenu = [...this.updatedSideNavMenu(acct)];
        });

    }

    ngOnDestroy() {
        this.datadogService.stopSessionReplayRecording();
    }

    selectCompany($event) {
        //sidenav is calling settings api to set its company
        if (this.account) {
            this.appService.setCompany($event.value).subscribe();
        }

    }
    private updatedSideNavMenu(acct) {
        const menu = [...this.sidenavMenu];
        const complianceUrl = `${environment.urls.complianceUrl}/` || `https://compliance.dev.zonarsystems.net/`;
        menu.map(item => {
            if (item.text === 'Compliance') {
                item.children.map(c => {
                    if (c.text === 'HoS' && acct !== '') {
                        c.routerLink = `${complianceUrl}?active=${acct}`;
                    }
                });
            }
        });
        return menu;
    }
}
