import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Store } from '@ngrx/store';

import { NgxPendoService } from 'ngx-pendo';
import { BehaviorSubject, EMPTY } from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, timeout} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { appName } from 'src/environments/shared';
import { AppRouteData } from './app.resolver';
import { AppService } from './app.service';
import { State as UserState } from './modules/udl/state';
import {setAccount, setUserProfileEntityId} from './modules/udl/state/user.actions';
import {accountSelector, dispatcherEntityIdSelector} from './modules/udl/state/user.selectors';
import {DataDogService} from '@app/modules/services/datadog.service';
import {Company} from '@zonar-ui/sidenav/lib/models/company.model';
import {
  desktopMenu, mobileMenu,
  sidenavGlobalConfig,
  sidenavHeaderConfig,
} from '@app/shared/sidenav.config';
import {SidenavComponent} from '@zonar-ui/sidenav';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-sub-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') zonarSideNav: SidenavComponent;
  public account;
  public hasDefaultCompany = false;
  public activeAccount = new BehaviorSubject<boolean>(false);
  public title = appName;
  public selectedCompany$ = new BehaviorSubject<any>(null);
  public dispatcherIsNull = false;
  public text = 'UDL assignment is unavailable. Contact customer care at (877) 843-3847 for assistance.';
  re = new RegExp(environment.swiftAccRegexCheck);

  sidenavConfig = sidenavGlobalConfig;
  sidenavHeader = sidenavHeaderConfig;
  sidenavMenu = undefined;



  private appRouteData: AppRouteData;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userStore: Store<UserState>,
    private ngxPendoService: NgxPendoService,
    private appService: AppService,
    private datadogService: DataDogService,
    private deviceService: DeviceDetectorService
    ) {
    const device = this.deviceService.getDeviceInfo();
    if (device.deviceType === 'mobile') {
      this.sidenavMenu = mobileMenu;
    } else {
      this.sidenavMenu = desktopMenu;
    }

    this.appRouteData = this.route.snapshot.data.data as AppRouteData;
    const aa = this.route.snapshot.queryParamMap.get('activeAccount');
    //add regex check here base do the environment - qaswi...., devswi...., etc. and update accounts and url
    if(aa !== this.appRouteData.activeAccount && this.re.test(aa)) {
        this.appService.updateAccountSelection(environment.swiftAccToChangeTo);
        this.userStore.dispatch(setAccount({ payload: environment.swiftAccToChangeTo }));
          //helps udpate the router and the activatedRoutes
        this.router.navigate([],   {
          relativeTo: this.route,
          queryParams: { activeAccount: environment.swiftAccToChangeTo},
          queryParamsHandling: 'merge'
        });
    }
    this.userStore.select(dispatcherEntityIdSelector).subscribe((x) => {
      if (this.account) {
        this.dispatcherIsNull = !x;
      }
    });
    this.activeAccount.next(!!this.appRouteData.activeAccount || !!this.appRouteData.selectedCompany);
    if (this.appRouteData.activeAccount && !this.appRouteData.selectedCompany) {
      this.account = this.appRouteData.activeAccount;
    }
    if (this.appRouteData.selectedCompany && this.appRouteData.selectedCompany.id) {
      this.initializePendo();
      this.hasDefaultCompany = true;
      // Setting selected company to header company to prevent the dialog open
      this.selectedCompany$.next(this.appRouteData.selectedCompany.id);
    }
  }
  ngOnInit() {
    this.datadogService.startSessionReplayRecording();
  }

  ngOnDestroy() {
    this.datadogService.stopSessionReplayRecording();
  }

  selectCompany($event) {
    this.setCompany($event);
  }

  setCompany(selection: { value: string; title: string }): void {
    // On initial load header component is emitting setCompany twice, so checking with previous id and current selection
    // if both are same then don't process set company.
    console.log('ELDX selected company', this.appRouteData);
    if (this.appRouteData.selectedCompany && this.appRouteData.selectedCompany.id === selection.value) {
      return;
    }
    if (this.appRouteData.userProfiles && this.appRouteData.userProfiles.length > 0) {
      this.appService.setCompany(selection.value, this.appRouteData.userProfiles[0])
          .pipe(map(company => {
                this.activeAccount.next(true);
                // Some times returned company is empty. So, set the company from selection if it's empty.
                if (!company) {
                  this.appRouteData.selectedCompany = this.getCompanyObject(selection);
                } else {
                  this.appRouteData.selectedCompany = company as any;
                }
                this.initializePendo();
              }),
              catchError((err) => {
                // Error happens when selected company doesn't have legacy account codes or an actual api error.
                this.datadogService.addRumError(err);
                const pnfData = {
                  title: 'UDL',
                  error: err.name,
                  errorMessage: err.message,
                  status: err.status,
                  url: environment.appUrl,
                };
                this.router.navigate(['404-page'], { queryParamsHandling: 'preserve' , state: pnfData});
                return EMPTY;
              })).subscribe();
    }
  }

  public onSideNavMobileMenuButtonToggled(event) {
    this.sidenavConfig.mobileOpened = event;
  }


  private getCompanyObject(selection: { value: string; title: string }): Company {
    return {
      name: selection.title,
      id: selection.value,
      anonymizedData: null,
      created: null,
      defaultDivisionId: null,
      modified: null,
    };
  }

  private initializePendo() {
    console.log('in initalize Pendo', this.appRouteData);
    const roleName = this.appRouteData.userProfiles?.length > 0
    && this.appRouteData.userProfiles[0].roles?.length > 0?
      this.appRouteData.userProfiles[0].roles[0]?.name : '';
    try {
      this.ngxPendoService.initialize({
        id: this.appRouteData?.user?.id,
        role: roleName,
        name: `${this.appRouteData?.user?.firstName} ${this.appRouteData?.user?.lastName}`
      }, {
        id: this.appRouteData?.selectedCompany?.id,
        name: this.appRouteData?.selectedCompany?.name,
      });
    } catch (e) {
      console.log(e);
    }
  }
}
